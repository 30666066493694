import React, { useState, useEffect, useCallback, useRef} from "react";
// one click, click outs, social, news, trending

import { IconActionButton } from "../IconActionButton";

import { DexPairItem } from "./DexPairItem";

import classNames from "classnames";

import { getTimeDisplay } from "../../utils/tools";
import { useSocial} from "../../hooks/useSocial";

import { useNavigator } from "../../hooks/useNavigator";
import { useDex } from "../../hooks/useDex";

export function TrendingChannelPanel({ tokens, name="test", cid, passTagClick, kid }) {


    const navigator = useNavigator();

    const { token, trendingTokensChannels, ethTrendingChain, ethTrendingKey } = useDex();

    const [ scrollTop, setScrollTop ] = useState(0);
    const [ optionsMenu, setOptionsMenu ] = useState(false);
    const [ panelHeight, setPanelHeight ] = useState(0);

    const ref = useRef(null);

    

    const handleScroll = useCallback(() => {
        // console.log("scrolling")
        let sTop = ref.current.scrollTop;
        setScrollTop(sTop);

        // if(sTop > (ref.current.scrollHeight-ref.current.offsetHeight-700)){
        //     let sc = 1*showPostCount;
        //     sc += 5;
        //     setShowPostCount(sc);
        // }
    }, [  ])

    // // Attach the scroll listener to the div
    useEffect(() => {
        const div = ref.current;
        div.addEventListener("scroll", handleScroll)
    }, [handleScroll])

    useEffect(() => {
        const div = ref.current;
        // let height = div.scrollHeight;
        //
        // setPanelHeight(height);
    }, [])

    const handleClick = () => {
        // passClick(post);
    }

    const channelClick = () => {
        // navigator.push(`/social/user/${tc.name}`);
    }

    let headerIndex = scrollTop < 80 ? 0 : scrollTop > 150 ? 1 : (scrollTop-80)/50;

    // let headerIndex = scrollTop < 150 ? 0 : scrollTop > 150 ? 1 : (scrollTop-100)/50;

    // let orderedPosts = twu.posts.filter(p => p.tags)
    // .sort((a, b) => {
    //     return new Date(b.created) - new Date(a.created);
    // });

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }

    return (

        <div
        ref={ref}
        id={`${cid}`}
        //onClick={() => switchTwitterUser(twu)}
        //className={classNames('social-item', twid == twu.twid && 'active')}
        style={{background:"transparent",paddingBottom:111}}
        className="social-item"
        >

            <div style={{ opacity: headerIndex,  pointerEvents: headerIndex < 1 ? 'none' : 'all',display: "flex" }} className="social-item-header-micro">
                {/*<p className="time">{getTimeDisplay(twu.updated)}</p>*/}
                <div onClick={channelClick} className="data-panel interactive flex-row align center">
                    <img
                        className="profile-image"
                        src={require(`../../icons/trending/logo_${[cid]}.png`)}
                        // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                        />
                    
                    <p>{name}</p>
                    { trendingTokensChannels[cid] !== undefined ? (
                        <div style={{marginTop:1,marginLeft:11,opacity:.7}} className="rank-number rank-2">{trendingTokensChannels[cid].count}</div>
                    ):null}
                </div>

                <div style={{transform: 'scale(.9)',marginRight:7}} className="svg-icon">
                    <img src={require(`../../icons/logo_chain_${ethTrendingChain}.png`)} />
                </div>

                {/* <div style={{marginLeft: 10}}>
                    <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }  passClick={toggleOptionsMenu}/>
                </div> */}
            </div>

            <div className="social-item-header">

                <div onClick={channelClick} className="data-panel interactive flex-row align center">
                    <img
                        className="profile-image"
                        src={require(`../../icons/trending/logo_${[cid]}.png`)}
                        // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                        />
                    
                    <p>{name}</p>

                    { trendingTokensChannels[cid] !== undefined ? (
                        <div style={{marginTop:1,marginLeft:11,opacity:.7}} className="rank-number rank-2">{trendingTokensChannels[cid].count}</div>
                    ):null}
                    
                </div>

                <div style={{transform: 'scale(.9)',marginRight:7}} className="svg-icon">
                    <img src={require(`../../icons/logo_chain_${ethTrendingChain}.png`)} />
                </div>

                {/* <div style={{marginLeft: 10}}>
                    <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" } passClick={toggleOptionsMenu}/>
                </div> */}

            </div>

            <>
                { tokens
                .filter(t => t.trending[cid] !== undefined)
                .sort((a,b) => a.trending[cid].rank > b.trending[cid].rank ? 1 : -1 )
                .map((tp,i) => (
                    <DexPairItem
                        key={`tdpi${i}`}
                        withSpace={true}
                        i={i}
                        rankAnimation={false}
                        // passClick={onDexTokenClick}
                        pairData={tp.data}
                        pair={tp}
                        cid={cid}
                        coreToken={token}
                    />
                    
                ))}
            </>
           
        </div>
    )
}
