import React, { useEffect, useState, useRef } from "react";

import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";

import { FilterSegment } from '../FilterSegment';

import { InputTextField } from "../Input/InputTextField";
import { LoaderSpinner } from "../Layout/LoaderSpinner";

import { ViewToolbar } from "../Social/ViewToolbar";

import classNames from "classnames";

import { adminWalletList, eTrendingData, eTrendingNames } from "../../utils/data";

import {ReactComponent as ClockIcon} from '../../icons/svgs/clock.svg';
import { TrendingChannelPanel } from "../Dex/TrendingChannelPanel";
import { scroll } from "viem/chains";


export function PanelsDashboard({ type="trending", channels=[], visible=true }) {

    const navigator = useNavigator();
    const { screens } = useNavigator();

    const socialContainerRef = useRef(null);


    const {
        chainPanel,
        handleChainPanel,
        sidebarRight,
        handleSidebarRight,
        handleScrollHorizontalPage
    } = useCore();
    
    const { user, walletAddress } = useWallet();
    // const { view,
    //     loadingSocial,
    //     twitterUsers,
    //     searchTwitterUsers,
    //     twitterUser,
    //     fetchTwitterUserByUsername,
    //     timeFilter,
    //     handleTimeFilter,
    //     twitterSearchedUser,
    //     twitterUserLists,
    //     updateTwitterUserList,
    //     handleTwitterUserLists,
    //     handleTwitterUsers,
    //     listSlug
    // } = useSocial();

    const { loading,
        tokenPair,
        dexChartVisible,
        handleDexChartVisible,
        swapVisible,
        apeSettings,
        handleScreenClickDims,
        searchDex,
        ethTrendingChain,
        ethTrendingKey,
        trendingTokens
    } = useDex();


    useEffect(() => {
 
        scrollToElement(socialContainerRef, ethTrendingKey);
            
    }, [ethTrendingKey, socialContainerRef]);


    const scrollToElement = (containerRef, elementId) => {
        if (!containerRef.current) return;
      
        const container = containerRef.current;
        const element = document.getElementById(elementId);
  
        if (element) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
      
          // Calculate the position relative to the container
          const scrollLeftPosition = elementRect.left - containerRect.left + container.scrollLeft;
         
          handleScrollHorizontalPage(scrollLeftPosition);
        
        }
    };

   

    

   

    const timeFilters = [
        {
            name: '1d',
            value: "1"
        },
        {
            name: "3d",
            value: "3"
        },
        {
            name: "7d",
            value: "7"
        },
        {
            name: "1m",
            value: "30"
        },
        {
            name: "all",
            value: "max"
        }
    ];

   

   

    const onTagClick = async (e,t,type="default") => {

        // console.log('lets go', e,t);
        // let ex = e.clientX;
        // let ey = e.clientY + 33;
        // handleScreenClickDims(ex,ey);
        // let tagtype = t.charAt(0);
        // if(tagtype == '$' || type=="dex"){
        //     let q = t.replace('$','');
        //     searchDex(q);
        // }else if(tagtype=="@"){
        //     let un = t.replace("@","");
        //     fetchTwitterUserByUsername(un);
        // }


    };


    

    // const twitterUsersFiltered = twitterUserLists[listSlug] !== undefined ?
    // [] : twitterUsers.filter(tu => {
    //     if(twitterUserLists[listSlug] == undefined){
    //         return false;
    //     }else{
    //         console.log(twitterUserLists[listSlug].twitterUsers.includes(tu._id))
    //         return twitterUsers.includes(tu._id);
    //     }

    // });

    return (
        <div ref={socialContainerRef} style={{overflowX:"auto"}} className={classNames("social-dashboard", visible && "visible")} >
           


            <div className="social-list" style={{}}>
                { ( ( loading.dex && !sidebarRight )) && (
                    <LoaderSpinner/>
                )}

                <div style={{overflow:"hidden"}} className={classNames('list-wrapper')}>

                   
                    <div className="social-content">

                        { Object.keys(eTrendingData[ethTrendingChain]).map((k, i) => (
                            <TrendingChannelPanel key={i} name={eTrendingNames[k]} cid={k} tokens={trendingTokens} />
                        ))} 

                    </div>
                </div>
            </div>
        </div>
    );
}
