import React, { useEffect, useState } from "react";

import { useMarket } from "../../hooks/useMarket";
import { useCore } from "../../hooks/useCore";
import { useCollection } from "../../hooks/useCollection";
import { useNavigator } from "../../hooks/useNavigator";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";

import toast from 'react-simple-toasts';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from 'react-tooltip';

import { TwitterTag } from "../Social/TwitterTag";
import { DexToolBarLinksOut } from "./DexToolbarLinksOut";

import classNames from "classnames";

import { wethAddress } from "../../utils/tools";

import dexscreenerlogo from "../../icons/logo_dexscreener.png";
import {ReactComponent as HealthIcon} from '../../icons/svgs/health.svg';

export function DexHeader({ addClass, disabled=false }) {

    const navigator = useNavigator();
    let { contractAddress, handleContractAddress, screens } = useNavigator();
    let { handleHealthCheck,
        dexChartVisible,
        handleDexChartVisible,
        handleSwapVisible,
        apeSettings,
        token,
        dexView,
        showTicker
    } = useDex();

    const { fullscreen, toggleFullscreen, modalVisible, handleModalVisible, qrModal, handleQrModal, handleQrurl, handleModalTitle, handleCollectionModal } = useCore();
    const { assetToAdd, handleAssetToAdd, collections, handleShowCollectionsOptions, showCollectionsOptions } = useCollection();
    const [copied, setCopied] = useState(false);



    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );


    const handleClick = () => {
        // passClick(nftp.address);
    }

    const open3d = () => {
        // navigator.push(`/experiments/${collection.token}`);
    }



    const showQRModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // handleModalTitle(nft.name + ' - #' + nft.token_id);
        // let qu = `${process.env.REACT_APP_ARTEMIS_URL_IP}/assets/${ca}/${nft.token_id}`;
        // console.log(qu);
        // handleQrurl(qu);
        let mt = token.symbol + "_" + token.name.replace(/\s+/g, "_");
        
        handleModalTitle(mt);

        handleQrurl(`${process.env.REACT_APP_ARTEMIS_APP_URL}/${token.pair.data.chainId}/${token.pair.address}`);

        handleModalVisible(true);
        handleQrModal(true);

    }


    const openQRUrl = (e) => {
        // e.preventDefault();
        // e.stopPropagation();
        //
        //
        // let qu = `${process.env.REACT_APP_ARTEMIS_URL_IP}/assets/${nft.token_address}/${nft.token_id}`;
        // window.open(
        //     qu,
        //     'qru'
        //     // 'toolbar=0,status=0,width=548,height=325'
        // );
    }



    function copy() {
        setCopied(true);
        toast('URL copied');

        setTimeout(function () {
            setCopied(false);
        }, 3000);
    }

    const checkHealth = () => {
        handleHealthCheck(token.address);
    }


    function showAddContract(e){
        // e.stopImmediatePropagation();
        // console.log("event.target:", e.target.id);
        // console.log("event.currentTarget", e.currentTarget);


        // if(collections.length < 1){
        //     handleCollectionModal(true);
        //     handleModalVisible(true);
        // }else{
        //     if(showCollectionsOptions){
        //         // handleShowCollectionsOptions(false, "off","off");
        //     }else{
        //         handleAssetToAdd(nft);
        //
        //         let ex = e.clientX;
        //         let ey = e.clientY + 33;
        //         handleShowCollectionsOptions(!showCollectionsOptions, ex, ey );
        //
        //     }
        // }


    }

    function showAddList(e){
        // e.stopImmediatePropagation();
        // console.log("event.target:", e.target.id);
        // console.log("event.currentTarget", e.currentTarget);
        if(collections.length < 1){
            handleCollectionModal(true);
            handleModalVisible(true);
        }else{
            if(showCollectionsOptions){
                // handleShowCollectionsOptions(false, "off","off");
            }else{
                // handleAssetToAdd(nft);
                //
                // let ex = e.clientX;
                // let ey = e.clientY + 33;
                // handleShowCollectionsOptions(!showCollectionsOptions, ex, ey );

            }
        }
    }

    let tag = !token || token?.symbol == undefined ? '--' : token.symbol?.charAt(0) == '$' ? token.symbol : `$${token.symbol}`;

    return (

        <div className="flex-row">
            {/*<div style={{marginRight:7}} className="coin-links">
                <div onClick={showQRModal} data-tip="QR code" className="coin-link">
                    <div className="svg-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                        <g><path d="M377.1,377.1H10V10h367.1V377.1z M78.6,308.5h229.8V78.6H78.6V308.5L78.6,308.5z"/><path d="M132.4,132.4h122.4v122.4H132.4V132.4z"/><path d="M377.1,990H10V621.8h367.1V990z M78.6,921.4h229.8v-231H78.6V921.4L78.6,921.4z"/><path d="M132.4,745.3h122.4v121.2H132.4V745.3z"/><path d="M990,377.1H621.8V10H990V377.1z M690.4,308.5h231V78.6h-231V308.5z"/><path d="M745.3,132.4h121.2v122.4H745.3V132.4z"/><path d="M10,377.1h122.4v123.5H10V377.1z"/><path d="M254.7,500.6h122.4v121.2H254.7V500.6L254.7,500.6z"/><path d="M500.6,10h121.2v122.4H500.6V10z"/><path d="M377.1,254.7h123.5v122.4H377.1V254.7z"/><path d="M500.6,866.5h121.2V990H500.6V866.5z"/><path d="M745.3,866.5H990V990H745.3V866.5z"/><path d="M500.6,621.8h244.7v123.5H500.6V621.8z"/><path d="M745.3,621.8h121.2v244.7H745.3V621.8z"/><path d="M855.1,621.8H990v123.5H855.1V621.8z"/><path d="M500.6,377.1h121.2v123.5H500.6V377.1z"/><path d="M621.8,500.6h123.5v121.2H621.8V500.6L621.8,500.6z"/><path d="M745.3,377.1h121.2v123.5H745.3V377.1z"/><path d="M866.5,500.6H990v121.2H866.5V500.6L866.5,500.6z"/></g>
                        </svg>

                    </div>
                </div>
            </div>*/}

            {/*{ dexView == "chart" || dexView == "swap" || !showTicker ? (*/}
                <DexToolBarLinksOut/>
            {/*):null}*/}

        </div>

    )
}
