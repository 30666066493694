import React, { useEffect, useRef, useState, useCallback } from "react";

import { FilterSegment } from '../FilterSegment';
import { InputSelect } from "../Input/InputSelect";
import { DexPairItem } from "../Dex/DexPairItem";
import { TokenSymbolIcon } from "./TokenSymbolIcon";
import { StatusLight } from "./StatusLight";
import LaunchGraph from "./LaunchGraph";
import { TokenToolbar } from "./TokenToolbar";

import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";
import { useWallet } from "../../hooks/useWallet";

import { client_dexscreener } from "../../services/api_dexscreener";

import classNames from "classnames";

import { formatCurrency, formatCurrencyScale, condenseZeros, formatPercent } from "../../utils/currency";

import { getTimeDiff, blankAddress, deadAddress, getTimeDisplay } from "../../utils/tools";

import { tokenCats } from "../../utils/data";

import { ReactComponent as RocketIcon } from '../../icons/svgs/rocket.svg';

import { SwapIcon } from "./SwapIcon";

var parse = require('html-react-parser');

export function PairList({ pairs, height, visible }) {

    const { user } = useWallet();

    const now = new Date();

    let {
        dexSearchPairs,
        dexSearch,  // string
        searchDex, // function to search
        fetchFromDex, // get by ca
        handleDexSearch,
        handleTokenPair,
        handleTokenToolbarVisible,
        loading,
        token,
        caToken,
        fetchTokenPair,
        handleTokenOrder,
        handleTokenSort,
        handleTokenFilters,
        tokenOrder,
        tokenFilters,
        tokenSort,
    } = useDex();


    const [tableHeight, setTableHeight] = useState('auto');
    const [columnIndex, setColumnIndex] = useState(null);
    const tableContainerElement = useRef(null);
    const tableElement = useRef(null);

    const [tokenCategory, setTokenCategory] = useState("newpairs");
    const minCellWidth = 44;

    const navigator = useNavigator();

    const { screens, pathname, handleSearchParams, searchParams } = useNavigator();


    useEffect(() => {
        setTableHeight(tableElement.current.offsetHeight);
        const currentParams = Object.fromEntries([...searchParams]);
        // console.log(currentParams);
        // handleSearchParams({
        //     ...currentParams,
        //     ...filters
        // });
        // console.log('handle filters lets go', pairs)
        handleTokenFilters(currentParams);
    }, []);

    const sortColumns = [
        {
            title: "token",
            value: "name",
            ref: useRef(),
            // style: {width:'155px'}
        },
        // {
        //     title: "pair",
        //     value:"symbol",
        //     ref: useRef(),
        // },
        // {
        //     title: "chart",
        //     // class: "noclick",
        //     ref: useRef(),
        // },

        {
            title: "age",
            value: "liquidityAt",
            // style:{width:64},
            ref: useRef(),
        },

        {
            title: "status",
            value: "contractScore",
            // class: "noclick",
            ref: useRef(),
        },
        {
            title: "score",
            value: "contractScore",
            // style:{width:55},
            ref: useRef(),
        },
        {
            title: "price",
            value: "data.priceUsd",
            ref: useRef(),
        },
        {
            title: "fdv",
            value: "data.fdv",
            ref: useRef(),
        },
        {
            title: "liq",
            value: "data.liquidity.usd",
            ref: useRef(),
        },
        {
            title: "volume",
            value: "data.volume.h24",
            ref: useRef(),
        },
        {
            title: "5m",
            value: "data.priceChange.m5",
            ref: useRef(),
        },
        {
            title: "1h",
            value: "data.priceChange.h1",
            ref: useRef(),
        },
        {
            title: "6h",
            value: "data.priceChange.h6",
            ref: useRef(),
        },
        {
            title: "24h",
            value: "data.priceChange.h24",
            ref: useRef(),
        },
        {
            title: "links",
            class: "noclick",
            ref: useRef(),
        },
    ];

    const mouseMove = useCallback((e) => {
        // if(tableElement){

            const gridColumns = sortColumns.map((col, i) => {
                // console.log(col, i, columnIndex);
                if (i === columnIndex) {
                // Calculate the column width
                    const width = e.clientX - col.ref.current.offsetLeft;
                    // console.log(width)
                    if (width >= minCellWidth) {
                        return `${width}px`;
                        console.log(width)
                    }
                }

                // Otherwise return the previous width (no changes)
                return `${col.ref.current.offsetWidth}px`;
        	});

        	// Assign the px values to the table
        	tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
        // }

    }, [columnIndex, sortColumns, minCellWidth]);

    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setColumnIndex(null);
        removeListeners();
    }, [setColumnIndex, removeListeners]);


    useEffect(() => {
        tableContainerElement.current.scrollTo(tableContainerElement.current.scrollLeft,3);
    }, [pairs]);


    useEffect(() => {
        if (columnIndex !== null) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }

        return () => {
            removeListeners();
        }
    }, [columnIndex, mouseMove, mouseUp, removeListeners]);




    const onDexTokenClick = async (t) => {
        // console.log(t)
        navigator.push(`/${t.data.chainId}/${t.data.pairAddress}`);
    }


    {/*<th onClick={() => handleFilter("token")} style={{ width: '155px' }}>token</th>
    <th>pair</th>
    <th className="noclick">chart</th>
    <th style={{width:64}}>age</th>
    <th className="noclick" style={{ width: '111px',  }}>status</th>
    <th style={{ width: '55px',  }}>score</th>
    <th>price</th>
    <th>fdv</th>
    <th>liq</th>
    <th>24h vol</th>
    <th>5m</th>
    <th>1h</th>
    <th>6h</th>
    <th>24h</th>*/}







    // const fTokens = user && user.hideTokens !== undefined ? Object.keys(tokens)
    // // .filter(
    // //     key => !user.hideTokens.includes(tokens[key]._id) &&
    // //     (tokens[key].health !== undefined && !tokens[key].health.isHoneypot)
    // // )
    // .map(key => (
    //     <DexPairItem key={key} passClick={onDexTokenClick} tokenPair={tokens[key]}/>
    // ))
    // // .sort((a,b) => {
    // //     if(getTimeDiff(now,tokens[b.key].liquidityAt)<.2){
    // //         return 1;
    // //     }else{
    // //         return tokens[a.key].priceChangedUpAt > tokens[b.key].priceChangedUpAt ? -1 : 1
    // //     }
    // //
    // // })
    //
    // : '';





    const fTokens = user && user.hideTokens !== undefined ? Object.keys(pairs)
    // .filter(
    //     key => pairs[key] && !user.hideTokens.includes(pairs[key].token?._id) &&
    //     ((tokenSort == "liquidityAt" && pairs[key].new)
    // )       || (tokenSort !== "liquidityAt" && pairs[key].new == undefined))
    .filter(
        key => pairs[key] && pairs[key].token && pairs[key].data && !user.hideTokens.includes(pairs[key].token?._id) )
    .map(key => (
        <tr className={classNames("dex-row", token && token.address == pairs[key].token.address && "active")} key={key} onClick={() => onDexTokenClick(pairs[key])}>
            <td>
                <div  className="flex-row align center">
                    <div className="active-bar"/>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:11}}>

                        <img data-tip={pairs[key].data.chainId} className="chain-icon" src={require(`../../icons/logo_chain_${pairs[key].data.chainId}.png`)}/>

                        <div data-tip={pairs[key].data.dexId} className="svg-icon raw swap-small grey">
                            <SwapIcon swap={pairs[key].data.dexId}/>
                        </div>

                    </div>
                    <TokenSymbolIcon
                      token={pairs[key].token}
                      // tokenPair={pairs[key]}
                      ca={pairs[key].token.address}
                      symbol={pairs[key].symbol}
                      name={pairs[key].name}
                    //   maxWidth={160}
                      minWidth={160}
                      // addClass="large"
                    />
                </div>
            </td>
            {/*
            <td className="ellipsis" style={{fontSize:10,}}>{pairs[key].data.baseToken.symbol} <span className="small">/</span> {pairs[key].data.quoteToken.symbol}</td>
*/}
            {/* <td>
                <div style={{padding:'5px 2px',minHeight:53}} className="data-panel">
                    { pairs[key].data !== undefined && pairs[key].launchProfile !== undefined ? (
                        <LaunchGraph p={pairs[key]} timeData={pairs[key].launchProfile} />
                    ):null}

                </div>

            </td> */}
            <td>{getTimeDisplay(pairs[key].liquidityAt)}</td>
            <td>
                <div className="flex-row">
                    <StatusLight data-tip="renounced" status={pairs[key].token?.renouncedAt || pairs[key].token?.owner == blankAddress ? 1 : 0}/>
                    <StatusLight data-tip="honeypot" status={pairs[key].health?.isHoneypot ? 0 : 1}/>
                    <StatusLight data-tip="liquidity" status={pairs[key].data.liquidity !== undefined && pairs[key].data.liquidity.usd > 100 ? 1 : 0}/>

                    <StatusLight data-tip="liquidity lock" status={pairs[key].liquidityLockedAt ? 1 : 0}/>
                    <StatusLight data-tip="proxy check" status={pairs[key].token?.proxy && pairs[key].token?.proxy == "none" ? 1 : 0.5}/>


                    {/*<p>{tokenPair.health?.buyTax}/{tokenPair.health?.sellTax}</p>*/}
                    <StatusLight data-tip="tax" status={pairs[key].health && pairs[key].health.sellTax < 5 && pairs[key].health.buyTax < 5 ? 1 : pairs[key].health?.sellTax < 10 && pairs[key].health?.buyTax < 10 ? .5 : 0}/>
                    <StatusLight data-tip="renounced when launched" status={!pairs[key].token?.ownerOg && pairs[key].token?.renouncedAt ? 0 : 1}/>
                    <StatusLight data-tip="assembly in contract" status={pairs[key].token?.assemblyInContract ? 0 : 1}/>
                    <StatusLight data-tip="verified" status={pairs[key].token?.verified ? 1 : 0}/>

                </div>
            </td>
            <td style={{ fontWeight:'bold',fontSize:18 }}>{pairs[key].contractScore <= 1 ? "0" : pairs[key].contractScore}</td>
            <td style={{fontSize:14}} className="mute-first">{parse('' + condenseZeros(formatCurrency(pairs[key].data.priceUsd, "USD")))}</td>
            <td><span className="small">$</span>{parse('' + formatCurrencyScale(pairs[key].data.fdv))}</td>
            <td className="mute-first"><span className="small">$</span>{parse('' + formatCurrencyScale(pairs[key].data.liquidity.usd))}</td>
            <td className="mute-first"><span className="small">$</span>{parse('' + formatCurrencyScale(pairs[key].data.volume.h24))}</td>
            { pairs[key].data.priceChange.m5 !== undefined ? (
                <>
                    <td>{parse(formatPercent(String(pairs[key].data.priceChange.m5)))}</td>
                    <td>{parse(formatPercent(String(pairs[key].data.priceChange.h1)))}</td>
                    <td>{parse(formatPercent(String(pairs[key].data.priceChange.h6)))}</td>
                    <td>{parse(formatPercent(String(pairs[key].data.priceChange.h24)))}</td>
                </>
            ):(
                <>
                    <td>{parse(formatPercent(String(0)))}</td>
                    <td>{parse(formatPercent(String(0)))}</td>
                    <td>{parse(formatPercent(String(0)))}</td>
                    <td>{parse(formatPercent(String(0)))}</td>
                </>
            )}

            <td>
                { pairs[key] && ( pairs[key].token.cg || (pairs[key].token.contractUrls !== undefined && pairs[key].token.contractUrls.length) ) ? (
                    <TokenToolbar tp={pairs[key].token}/>
                ):null}
            </td>
        </tr>
    ))
    .sort((a,b) => {
        const sortA = getNestedValue(pairs[a.key], tokenSort);
        const sortB = getNestedValue(pairs[b.key], tokenSort);
        if(tokenOrder){
            return sortA > sortB ? -1 : 1;
        }else{
            return sortA < sortB ? -1 : 1;
        }

        // return tokens[a.key][tokenSort] > tokens[b.key][tokenSort] ? -1 : 1




    })

    : '';



    function getNestedValue(obj, key) {
        const keys = key.split('.');
        let result = obj;

        for (let i = 0; i < keys.length; i++) {
            result = result[keys[i]];

            if (typeof result === 'undefined') {
                return undefined;
            }
        }

        return result;
    }
    // if(getTimeDiff(now,tokens[b.key].liquidityAt)<.2){
    //     return 1;
    // }else{
    //     return tokens[a.key].priceChangedUpAt > tokens[b.key].priceChangedUpAt ? -1 : 1
    // }


    const handleFilter = (f) => {
        console.log(f)
        // handleTokenFilter(f);
    }

    const handleSort = (s) => {
        console.log(s)
        if(tokenSort == s){
            handleTokenOrder(!tokenOrder);
        }else{
            handleTokenSort(s);
        }

        tableContainerElement.current.scrollTo(tableContainerElement.current.scrollLeft,0);
    }

    const onColumnMouseDown = (index) => {
      setColumnIndex(index);
    //   console.log(index)
    }


    return (
        <div
        // style={{maxHeight: `calc( 100vh - 64px - ${height} )`}}
        ref={tableContainerElement}
        className={classNames("dex-table-wrapper", visible && "visible")} >

            <table className="dex-table" ref={tableElement}>
                <thead className="dex-table-header">
                    <tr>


                        {sortColumns.map((s, i) => (
                            <th ref={s.ref} key={i}
                            onClick={() => handleSort(s.value)}
                            className={classNames(s.class ? s.class : "", tokenSort == s.value && "active")}
                            style={s.style ? s.style : {}}
                            >

                                <span>{s.title}</span>
                                <div
                                  style={{ height: tableHeight }}
                                  onMouseDown={() => onColumnMouseDown(i)}
                                  className={`resize-handle ${columnIndex === i ? 'active' : 'idle'}`}
                                />
                            </th>
                        ))}


                    </tr>
                </thead>
                <tbody style={{position:'relative',top:0}}>
                    {/*<tr>
                        <td>
                            <p>test</p>
                        </td>
                    </tr>*/}

                    {fTokens}


                </tbody>
            </table>


        </div>
    );
}
