import React, { useEffect, useState } from "react";

import { IconActionButton } from "../IconActionButton";

import { useCore } from "../../hooks/useCore";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";

import classNames from "classnames";

import { sleep, createHyperlinks } from "../../utils/tools";

import dingSound from '../../media/sounds/message.mp3';

import { ReactComponent as Seedling } from "../../icons/svgs/seedling.svg";
import { ReactComponent as Newspaper} from "../../icons/svgs/newspaper.svg";
import { ReactComponent as Wallet} from '../../icons/svgs/wallet.svg';
import { ReactComponent as Flame} from "../../icons/svgs/flame2.svg";
import { ReactComponent as Megaphone} from "../../icons/svgs/megaphone.svg";
import {ReactComponent as RetweetIcon} from '../../icons/svgs/retweet.svg';

import { TokenSymbolIcon } from "../Dex/TokenSymbolIcon";
import { SwapIcon } from "../Dex/SwapIcon";

const notificationSound = new Audio(dingSound);
notificationSound.volume = .44;

var parse = require('html-react-parser');



export function Notification({ n }) {

    const navigator = useNavigator();

    let { closeNotification, showNotifications, handleListPage, handleDexSearchModal, handleModalVisible } = useCore();
    let { token, handleDexView, handleListView, handleDexSearch, searchDex } = useDex();
    const [ addShow, setAddShow ] = useState(false);
    const [ intervalTimer, setIntervalTimer ] = useState(0);

    var closeInterval;
    var timer = 0;
    

    const frameInterval = 33;


    let [count, setCount] = useState(0);
    let [hover, setHover] = useState(false);






    useEffect(() => {
        let interval = setInterval(() => {
            // setCount(count + 1)
            if(!hover){
                setCount(count => count + 1)
            }
            

        }, frameInterval)

        return () => {
            clearInterval(interval);
        }
    }, [hover])

    useEffect(() => {

        setTimeout(() => {
           
            setAddShow(true);
            
            // notificationSound.play();
        },100);

    }, []);

  

    useEffect(() => {
        // console.log(count, (time * frameInterval))
        if(count > n.time * frameInterval){
            closeNot();
        }

    }, [ count ]);





    const closeNot = async () => {

        // setAddShow(false);
        // await sleep(500);
        // setAddShow(true);
        closeNotification(n);
        // setTimeout(() => {
        //     closeNotification(n);
        //
        // },1000);
    }

    const onHover = () => {
        // clearInterval(closeInterval);
        setHover(true);
    }

    const onHoverOut = () => {
        // startCloseIntervalTimer();
        setHover(false);
    }

   

    const notificationClick = (e) => {

        let target = e.target;

        handleDexView("list");

        console.log(target.tagName);

        if (target.tagName === "A") {
            // Prevent the default action of the anchor tag and stop the event from bubbling up
            e.preventDefault();
            e.stopPropagation();
            
            // Open the link in a new window
            window.open(target.href, "_blank");
        } else {
            if(n.type == "pair"){
                if(n.data.pa && n.data.pa !== "null"){
                    navigator.push(`/${n.data.chain}/${n.data.pa}`)
                }else if(n.data.ta && n.data.ta !== "null"){
                    navigator.push(`/${n.data.chain}/${n.data.ta}`)
                }
                
            }
            if(n.type == "xpost"){
                window.open(`https://x.com/${n.data.username}/status/${n.data.post_id}`, "_blank");
            }
            if(n.type == "news"){
                if(token){
    
                    handleListView('news');
                    handleListPage(null);
                    navigator.push(`/${token.chainId}/${token.pair.address}?view=news`);
                    
                }else{
                    
                    navigator.push(`/news`)
                }
                
            }
            if(n.type == "call"){
                if(token){
                    
                    handleListView('calls');
                    handleListPage(null);
                    navigator.push(`/${token.chainId}/${token.pair.address}?view=calls`);
                    
                }else{
                    
                    navigator.push(`/calls`)
                }
                
            }
        }


        console.log(token)

        
    }

    const searchClick = (s) => {
		let st = s.replace("$","");
		handleDexSearch(st);
        searchDex(st);
		handleDexSearchModal(true);
        handleModalVisible(true);
	}

	
	
	const createLinksCalls = (text, onCashtagClick) => {
       // Regex patterns for URLs, usernames, and cashtags
        const urlPattern = /(https:\/\/[^\s]+)/g;
        const usernamePattern = /@(\w+)/g;
        const cashtagPattern = /\$(?![0-9])[A-Za-z0-9.]+/g;

        // Function to create JSX for the match
        const createElement = (match, type) => {
            if (type === 'url') {
            return (
                <a key={`${n.id}${match}`} href={match} target="_blank" rel="noopener noreferrer">
                {match}
                </a>
            );
            }
            if (type === 'username') {
            const username = match.slice(1); // Remove '@' symbol
            return (
                <a key={`${n.id}${match}`} href={`https://t.me/${username}`} target="_blank" rel="noopener noreferrer">
                @{username}
                </a>
            );
            }
            if (type === 'cashtag') {
            return (
                <a
                key={`${n.id}${match}`}
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCashtagClick(match); // Trigger cashtag click handler
                }}
                >
                {match}
                </a>
            );
            }
        };

        // Split the text based on the patterns and handle each part
        const parts = [];
        let lastIndex = 0;
        
        // Find all matches (URLs, usernames, cashtags) and split text accordingly
        const matches = [...text.matchAll(urlPattern), ...text.matchAll(usernamePattern), ...text.matchAll(cashtagPattern)];
        matches.sort((a, b) => a.index - b.index); // Sort by index to process in correct order
        
        // Process text between matches
        matches.forEach((match, idx) => {
            const [fullMatch] = match;
            const partStartIndex = match.index;

            // Push text before this match as a plain string
            if (partStartIndex > lastIndex) {
            parts.push(<span key={lastIndex}>{text.slice(lastIndex, partStartIndex)}</span>);
            }

            // Push the match as the correct JSX element (URL, username, cashtag)
            const type = fullMatch.startsWith('http') ? 'url' : fullMatch.startsWith('@') ? 'username' : 'cashtag';
            parts.push(createElement(fullMatch, type));

            // Update the lastIndex to be after this match
            lastIndex = partStartIndex + fullMatch.length;
        });

        // Add remaining text after the last match
        if (lastIndex < text.length) {
            parts.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
        }

        return <>{parts}</>;
    };

    const handleCashtagClick = (cashtag) => {
		console.log('Cashtag clicked:', cashtag);
		searchClick(cashtag);
	};

    

    const createLinksX = (text) => {
		
		// Regex for matching URLs starting with 'https'
		const urlPattern = /(https:\/\/[^\s]+)/g;
		// Regex for matching @username mentions
		const usernamePattern = /@(\w+)/g;
		
		// Replace URLs with anchor tags
		let linkedText = text.replace(urlPattern, url => `<a href="${url}" target="_blank">${url}</a>`);
		
		// Replace @username mentions with anchor tags to x.com/username
		linkedText = linkedText.replace(usernamePattern, (match, username) => `<a href="https://x.com/${username}" target="_blank">@${username}</a>`);
		
		return linkedText;
		  
	}

    const createLinksNews = (text) => {
		
		// Regex for matching URLs starting with 'https'
		const urlPattern = /(https:\/\/[^\s]+)/g;
		// Regex for matching @username mentions
		const usernamePattern = /@(\w+)/g;
		
		// Replace URLs with anchor tags
		let linkedText = text.replace(urlPattern, url => `<a href="${url}" target="_blank">${url}</a>`);
		
		// Replace @username mentions with anchor tags to x.com/username
		linkedText = linkedText.replace(usernamePattern, (match, username) => `<a href="https://x.com/${username}" target="_blank">@${username}</a>`);
		
		return linkedText;
		  
	}

    const createLinksTG = (text) => {
		
		// Regex for matching URLs starting with 'https'
		const urlPattern = /(https:\/\/[^\s]+)/g;
		// Regex for matching @username mentions
		const usernamePattern = /@(\w+)/g;
		
		// Replace URLs with anchor tags
		let linkedText = text.replace(urlPattern, url => `<a href="${url}" target="_blank">${url}</a>`);
		
		// Replace @username mentions with anchor tags to x.com/username
		linkedText = linkedText.replace(usernamePattern, (match, username) => `<a href="https://t.me/${username}" target="_blank">@${username}</a>`);
		
		return linkedText;
		  
	}

    const renderCaption = (t) => {
        switch(t){
            case "pair":
                return "new pair";
                break;
            case "news":
                return "news";
                break;
            case "call":
                return "call";
                break;
            case "xpost":
                return "post";
                break;
            default:
                return "new someting";
                
        }
      };

    const pwidth = ( 100 - ( count / (n.time * frameInterval)*100 ));

    const cMessage = n.data.message !== undefined ? createHyperlinks(n.data.message) : "";

    return (

        <div
        className={classNames('notification', n.type, n.id, addShow && 'show', token && token.address == n.data?.ta && 'active' )}
        onClick={notificationClick}
        onMouseEnter={onHover}
        onMouseLeave={onHoverOut}
        >
            {/* {n.type !== "pair" && n.type !== "call" && n.type !== "news" ? (
                <div style={{marginLeft:5,marginRight:5}}>
                    { n.type == "success" ? (
                        <div className="icon-button-check-circle"/>
                    ) : n.type == "warning" ? (
                        <div className="icon-button-triangle"/>
                    ) : n.type == "pair" ? (
                        <div style={{maxWidth:12}} className="svg-icon">
                            
                        </div>
                    
                    ) : n.type == "xpost" ? (
                        <>
                        </>
                        
                    ) : (
                        <div className="icon-button-minus-circle"/>
                    )}
                </div>
            ):null} */}
            
          
            <div style={{color: n.type=="xpost" ? "#0d0d0d" : "inherit",left:27,top:3,right:'initial', borderRadius:4}} className={classNames("meta-tag-primary", n.type == "xpost" && n.data.post_type == "tweet" ? "greenbg" : n.type == "xpost" ? "bluebg" : "")}>
                { n.type == "xpost" ? n.data.post_type : renderCaption(n.type) }
            </div>

            { n.type == "pair" || n.type == "news" ? (
                <div style={{position:"absolute",top:0,left:5}}>
                    
                    { n.type == "pair" ? (
                        <div className="flex-row align center">
                            <div style={{maxWidth:12,marginTop:-4}} className="svg-icon">
                                <Seedling/>
                                
                            </div>
                            
                        </div>
                        
                    ) : n.type == "news" ? (
                        <div style={{maxWidth:12}} className="svg-icon">
                            <Newspaper/>
                        </div>
                    ) : n.type == "call" ? (
                        <div style={{maxWidth:12}} className="svg-icon no-fill active">
                            <Megaphone/>
                        </div>
                    ):null}
                    
                
                </div>
            ):(
                <div style={{position:"absolute",top:-1,left:-4}}>
                    <div style={{opacity:.7}} className="notification-image">
                        {n.type == "xpost" ? (
                            <img src={require("../../icons/data/logo_x.png")}/>
                        ): n.type == "call" ? (
                            <img style={{width:17,height:17,marginLeft:-2,marginTop:4,filter: "saturate(0) brightness(1.5)"}} src={require(`../../icons/news/logo_telegraph.png`)}/>
                        ):null}
                        
                    </div>
                </div>
            )}


            {n.type == "pair" && n.data?.ta?.slice(-4) == "pump" ? (
                <div style={{position:"absolute",bottom:3,left:3}}>
                     <img style={{width:18,height:18}} src={require("../../icons/swaps/logo_pumpfun.png")}/>
               
                </div>            
                
                
            ):null}
                      

         

            <IconActionButton passStyle={{right:'5px',top:'5px'}} type="close" passClick={() => closeNot()}/>

            <div className="flex-column">

                { n.type == "pair" ? (
                    <div style={{paddingTop:5,paddingLeft:5,marginBottom:4}} className="flex-row align center">
                        <img style={{minWidth:18,marginTop:-5}} className="chain-icon" src={require(`../../icons/logo_chain_${n.data.chain}.png`)}/>
                        <div data-tip={n.data.swap} style={{minWidth:18,marginRight: 7,marginTop:-5 }} className="svg-icon raw swap-small">
                            <SwapIcon swap={n.data.swap}/>
                        </div>
                        <TokenSymbolIcon
                        ca={n.data.ta}
                        name={n.data.name}
                        symbol={n.data.symbol}
                        maxWidthIcon={22}
                        maxWidth={184}
                        showCa={true}
                        />
                    </div>
                ): (
                    <div style={{marginTop:5,paddingTop:4,paddingBottom:7,paddingLeft:7,alignItems:"flex-start"}} className="flex-row">
                        { n.type == "xpost" ? (
                            <img className="user-avatar" src={require(`../../icons/x/user_${n.data.username}.jpg`)}/>
                        ) : n.type == "call" ? (
                            <img className="user-avatar" src={require(`../../icons/kols/logo_${n.data.username.toLowerCase()}.jpg`)}/>
                        ) : n.type == "news" ? (
                            <img className="user-avatar" src={require(`../../icons/news/logo_${n.data.username}.png`)}/>
                        ) : null }
                        
                        <div style={{paddingLeft:9,alignItems:"flex-start"}} className="flex-column">
                            <div style={{lineHeight:'13px',marginBottom:3}} className="data-title highlight">{n.data.channelTitle}</div>
                            <p style={{margin:0,fontWeight:'500'}}>
                                { n.type == "xpost" ? (
                                    <>
                                        {parse(createLinksX(n.data.message))}
                                    </>
                                    
                                ): n.type == "call" ? (
                                    <>
                                    
                                        {createLinksCalls(n.data.message, handleCashtagClick)}
                                    </>
                                ): n.type == "news" ? (
                                    <>
                                        {parse(createLinksNews(n.data.message))}
                                    </>
                                ):(
                                    <>
                                        {n.data.message}
                                    </>
                                )}
                                
                            </p>
                        </div>

                        
                        
                    </div>
                )}


                {n.data.photo !== undefined && n.data.photo ? (
                    <div className="notification-photo">
                        <img src={`${process.env.REACT_APP_MEDIA_URL}${n.data.photo}`}/>
                    </div>
                ):null}


            </div>

            
            

            

            <div className="progress-bar">
                <div style={{width: pwidth + '%'}} className="progress-indicator"/>
            </div>

        </div>

    )

}
