import React, { useEffect, useState, useRef } from 'react';

import { Chart as ChartJS, RadialLinearScale, CategoryScale, LinearScale, BarElement, PointElement, LineElement, RadarElement, Title, Filler, Tooltip, Legend } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import {TreemapController, TreemapElement} from 'chartjs-chart-treemap';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useCore } from '../../hooks/useCore';
import { useDex } from '../../hooks/useDex';
import { useNavigator } from '../../hooks/useNavigator';
import { getVibrantColorGradient } from '../../utils/data';

ChartJS.register(CategoryScale, Title, Tooltip, Legend, LinearScale, TreemapController, TreemapElement, ChartDataLabels);

export function TreeMap({ tokens=[], dataTimeKey="h24", dataCriteria="price" }){

    const navigator = useNavigator();
    const { token } = useDex();

    const chartRef = useRef();
    const shouldDrawCircles = useRef(true);
    const latestDataRef = useRef(null); // Keep the latest data in a ref



    const [ data, setData ] = useState({
        labels: [],
        datasets: []
    });

    const [ options, setOptions ] = useState({});

    const [visible, setVisible] = useState(false);


    const { pageSize, ethTrending, listView } = useCore();
    const { ethTrendingKey, listPage } = useDex();

    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Track window width for responsive design

    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current; // Access the chart instance
            const canvas = chart.canvas; // Get the canvas element

            // Dispatch a mouseover event on the canvas
            const event = new MouseEvent('mousemove', {
                bubbles: true,
                cancelable: true,
                clientX: canvas.offsetLeft + canvas.width / 2, // Adjust the coordinates as needed
                clientY: canvas.offsetTop + canvas.height / 2,
            });

            canvas.dispatchEvent(event); // Fire the event
        }
    }, [data]);

    useEffect(() => {

        setVisible(false);

        let now = new Date();
        let d = {
          
            datasets: []
        }
        

        let treeData=[];

        let t=tokens.filter( t => t.trending[ethTrendingKey] !== undefined );

        for(var i=0;i<t.length;i++){

           
            let pcolor = {
                // bg: neutralBackgroundColor,
                // border: neutralBorderColor,
                // bghover: rollBackgroundColor,
                // borderhover: rollBorderColor
            };

            // let treeCell = tokens[i].data.fdv;
            let imgu = t[i].token?.cg ? t[i].token.cg.image.small : t[i].token?.iconImage ? `${process.env.REACT_APP_MEDIA_URL}${t[i].token.iconImage}` : null

            console.log(t[i])
            let treeCell = {
               
                v: i,
                fdv: t[i].data.fdv,
                fdvi: t[i].data.fdv > 5000000 ? 5000000 : t[i].data.fdv < 500000 ? 500000 : t[i].data.fdv,
                volume: t[i].data.volume[`${dataTimeKey}`],
                label: t[i].symbol,
                p: t[i].data.priceChange[`${dataTimeKey}`],
                address: t[i].address,
                chain: t[i].chainId,
                image: imgu,
                // category: tokens[i].address,
                
            };

            // console.log(tokens[i].data.fdv);


            treeData.push(treeCell);
           

        }

        let td = dataCriteria == "price" ?
        treeData.sort((a,b) => b.fdv > a.fdv ? 1 : -1 )
        : treeData.sort((a,b) => b.volume > a.volume ? 1 : -1 );

        // console.log(treeData);

        let fincolors = {
            green: getVibrantColorGradient(0,20,"green"),
            neutral: getVibrantColorGradient(Math.floor(Math.random()*7),20,"neutral"),
            red: getVibrantColorGradient(0,20,"red"),
        }

        let tempData = {
            datasets: [
              {
                label: 'Treemap',
                // hoverBackgroundColor: "#f33",
                // hoverBorderColor: "#33f",
                // hoverBorderWidth: 7,
    
                labels: {
                    color: '#fff', // Label color
                    // font: {
                    //     size: windowWidth < 640 ? 15 : 22, // Font size
                    //     weight: 'bold', // Font weight
                    //     family: 'Saira, sans-serif', // Font family
                    // },

                    font: (ctx) => {
                        const value = ctx.raw._data.fdvi;
                        const baseSize = 10; // Minimum font size
                        const maxSize = 24; // Maximum font size
                        const adjustedSize = Math.min(maxSize, baseSize + value / 700000); // Scale based on value
                        return {
                          size: adjustedSize,
                          family: 'Saira, sans-serif',
                          weight: 'bold'
                        };
                    },
                   
                    formatter: (ctx) => {
                       
                        let d = ctx.chart.data;
                        let p = d.datasets[ctx.datasetIndex].tree[ctx.dataIndex].p;
                        const mainLabel = `${ p > 0 ? "+" : ""}${p}%`; // Main label text
                       
                        return `${mainLabel}`; // Combining both main and sub labels
                    },

                    // formatter: (ctx) => `Value: ${ctx.raw.v}`,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Background color for labels
                    padding: 25, // Padding around the label text
                    borderRadius: 5, // Border radius for rounded corners
                    anchor: 'center', // Anchoring the label to the left
                    align: 'center', // Aligning the label to the left
                    offset: 10,
                    display: true,
                    spacing: 2,
                   
                },
                datalabels: {
                    // Sub label
                    display: true,
                    color: '#ddd', // Sub label color
                    // font: {
                    //     size: windowWidth < 640 ? 3 : 27, // Font size
                    //     weight: '600', // Font weight
                    //     family: 'Saira, sans-serif', // Font family
                    // },

                    font: (ctx) => {
                       
                        const value = ctx.dataset.tree[ctx.dataIndex].fdvi;
                        const baseSize = 10; // Minimum font size
                        const maxSize = 22; // Maximum font size
                        const adjustedSize = Math.min(maxSize, baseSize + value / 700000); // Scale based on value
                        return {
                          size: adjustedSize,
                          family: 'Saira, sans-serif',
                          weight: '600'
                        };
                    },

                  
                    formatter: (value, ctx) => {
                      const data = ctx.chart.data;
                      return `${data.datasets[ctx.datasetIndex].tree[ctx.dataIndex].label}`; // Sub label text
                    },
                    
                    anchor: 'center', // Anchor to the start of the block
                    align: 'top', // Align sub-label to the left
                    offset: (ctx) => {
                        
                        const value = ctx.dataset.tree[ctx.dataIndex].fdvi;
                        const baseSize = -11; // Minimum font size
                        const maxSize = 25; // Maximum font size
                        const aOffset = -15 + Math.min(maxSize, baseSize + value / 700000); // Scale based on value
                        return aOffset;
                    },
                },
                
                tree: treeData,
                key: dataCriteria == "price" ? 'fdv' : 'volume',      
                // groups: ['category'],
                backgroundColor: (ctx) => {
                    
                    if (ctx.type === 'data') {

                     
                      let p = ctx.raw._data.p;
                      let c = null;
                      let mag = p > 0 ? Math.ceil(Math.abs(p/2)) : Math.ceil(Math.abs(p/4));
                      
                      if(mag > 19){
                        mag = 19;
                      }
                      let a = .5 + .25*(mag/20);

                      if(p < 0){
                        c = `rgba(${fincolors.red[mag]}, ${a})`;
                      }else{
                        c = `rgba(${fincolors.green[mag]}, ${a})`;
                      }
                      const alpha = Math.min(.4, (p / 10000) + 0.1); // Adjust transparency based on value
                      return c; // Green shade
                    }
                    return 'transparent';
                },
                borderColor: '#777',
                borderWidth: .5,
                spacing: 1,
                // captions: {
                //     display: true,
                //     formatter(ctx) {
                //       return ctx.type === 'data' ? 'G: ' + ctx.raw.g : '';
                //     }
                // },
              },
            ],
        };

        

        const tempOptions = {
            animation: {
                duration: 0
            },
            treemap: {
                layout: {
                    paddings: {
                        top: 5,
                        right: 5,
                        bottom: 5,
                        left: 5,
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                // padding: {
                //     top: 73,
                //     left: 20,
                //     right: 20,
                //     bottom: 44
                // },
                padding: 0
            },
    
            onClick: (e, elements) => {
                if (elements.length > 0) {
                    // console.log(latestDataRef.current)
                    const index = elements[0].index;
                    let t = {...latestDataRef.current.datasets[0].tree[index]};
                    // console.log(data.datasets[0].tree, index);

                    let v = "trending";

                    if(listView=="trending"){
                        v += "&tc=" + ethTrendingKey;
                    }

                    // if(token){
                      
                        let lp = listView == "list" ? listPage : "";
                    
                        // setTimeout(() => {
                        //     navigator.push(`/${t.chain}/${t.address}?view=${listView}${lp.length ? `&${lp}` : ""}`);
                        // },200)
                    // }else{
                        
                        // setTimeout(() => {
                            navigator.push(`/${t.chain}/${t.address}?view=${v}`);
                        // },50)
                    // }

                   
                    // alert(`You clicked on ${clickedBlock} with `);
                }
            },
           
        
    
            onHover: (event, chartElement) => {
                const chartInstance = event.chart;
                
                const canvas = event.native?.target || event.chart.canvas;
            
                // If a point is hovered over, adjust the dataset styles
                if (chartElement.length > 0) {
                    shouldDrawCircles.current = true;
                    canvas.style.cursor = 'pointer';
                }else{
                    shouldDrawCircles.current = true;
                    canvas.style.cursor = 'default';
                }
            },
            
            plugins: {
                // tooltip: {
                // callbacks: {
                //     label: function (context) {
                //         const dataPoint = context.raw;
                //         return `${dataPoint.x}: ${dataPoint.v}`;
                //     },
                // },
                // },
                datalabels: {
                    color: '#36A2EB',
                    display: false
                },
                tooltip: false,
                legend: false,
                title: {
                    display: false,
                    text: 'Simple Treemap Chart',
                },


               
             
            },
        };


        latestDataRef.current = tempData;
        setOptions(tempOptions);
        setData(tempData);
        setVisible(true);
		
		
	}, [ tokens, ethTrendingKey, dataTimeKey, dataCriteria ]);


  // Update window width on resize
    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
      
    const minSizePlugin = {
        id: 'minSizePlugin',
        beforeDraw(chart) {
            const { ctx, data } = chart;
            const datasets = data.datasets;
    
            datasets.forEach((dataset, datasetIndex) => {
                dataset.tree.forEach((node, dataIndex) => {
                    const meta = chart.getDatasetMeta(datasetIndex).data[dataIndex];
                    const { x, y, width, height } = meta.getProps(['x', 'y', 'width', 'height']);
    
                    const MIN_DIMENSION = 20; // Minimum size in pixels
                    const adjustedWidth = Math.max(width, MIN_DIMENSION);
                    const adjustedHeight = Math.max(height, MIN_DIMENSION);
    
                    // Prevent resizing below the minimum size
                    ctx.fillStyle = meta.options.backgroundColor;
                    ctx.fillRect(x, y, adjustedWidth, adjustedHeight);
                });
            });
        },
    };
    

    const imagePlugin = {
        id: 'imagePlugin',
        afterDraw(chart) {
            if (!shouldDrawCircles.current) return;
            const { ctx } = chart;
            const { datasets } = chart.data;
    
            datasets.forEach((dataset, datasetIndex) => {
                dataset.tree.forEach((node, dataIndex) => {
                    const meta = chart.getDatasetMeta(datasetIndex).data[dataIndex];
                   
                    if (!meta) return;
    
                    const { x, y, width, height } = meta.getProps(
                        ['x', 'y', 'width', 'height'],
                        chart.options.animationDuration
                    );
    
                    const img = new Image();
                    img.src = node.image; // Image path from the dataset
    
                    // img.onload = () => {
                        const targetWidth = 40; // Desired image width
                        const aspectRatio = img.width / img.height;
                        const targetHeight = targetWidth / aspectRatio; // Preserve aspect ratio
    
                        // Calculate centered position within the cell
                        const imageX = x + width / 2 - targetWidth / 2; // Center horizontally

                        let yOff = -18 - Math.round(node.fdvi/400000);
                        
                        const imageY = y + height / 2 - targetHeight / 2 + yOff; // Center vertically and move 10px up
    
                        const borderRadius = 7; // Border radius in pixels
    
                        ctx.save();
    
                        // Create rounded rectangle path
                        ctx.beginPath();
                        ctx.moveTo(imageX + borderRadius, imageY);
                        ctx.lineTo(imageX + targetWidth - borderRadius, imageY);
                        ctx.arcTo(imageX + targetWidth, imageY, imageX + targetWidth, imageY + targetHeight, borderRadius);
                        ctx.lineTo(imageX + targetWidth, imageY + targetHeight - borderRadius);
                        ctx.arcTo(imageX + targetWidth, imageY + targetHeight, imageX + targetWidth - borderRadius, imageY + targetHeight, borderRadius);
                        ctx.lineTo(imageX + borderRadius, imageY + targetHeight);
                        ctx.arcTo(imageX, imageY + targetHeight, imageX, imageY + targetHeight - borderRadius, borderRadius);
                        ctx.lineTo(imageX, imageY + borderRadius);
                        ctx.arcTo(imageX, imageY, imageX + borderRadius, imageY, borderRadius);
                        ctx.closePath();
                        ctx.clip(); // Apply clipping region
    
                        // Draw the image
                        ctx.drawImage(img, imageX, imageY, targetWidth, targetHeight);
    
                        ctx.restore();
                    // };
                });
            });

        },
    };
    
    
  
    
  
    return (

        <div className="treemap-container"
        style={{
            margin: 0,
            width: pageSize.width,
            height: `${token ? pageSize.height * 1 - 123 : pageSize.height * 1 - 124}px` }}
        >
            {visible ? (
                <Chart ref={chartRef} type="treemap" data={data} options={options} plugins={[imagePlugin, minSizePlugin]} />
            ):null}
        

      </div>

    );
};