import React, { useMemo, useEffect, useState, useRef } from "react";

import { DexPairItem } from "./DexPairItem";
import { CallItem } from "../Social/CallItem";
import { TokenDataTabsInteractive } from "./TokenDataTabsInteractive";
import { Portfolio } from "../../pages/Portfolio";
import { HomeMeta } from "../Layout/HomeMeta";
import { ListPresentation } from "../Dex/ListPresentation";
import { TokenListItem } from "./TokenListItem";
import { NewsItem } from "../Social/NewsItem";
import { UserCard } from "../../components/Social/UserCard";
import { UserPage } from "../Social/UserPage";
import {RadarChart, RadarChartMemo} from "../Chart/RadarChart";

import { useDex } from "../../hooks/useDex";
import { PairList } from "./PairList";
import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";
import { useSocial } from "../../hooks/useSocial";

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";


import classNames from "classnames";

import { useNavigator } from "../../hooks/useNavigator";

import { LoaderSpinner } from "../Layout/LoaderSpinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import { ReactComponent as Flame} from "../../icons/svgs/flame2.svg";

import { ReactComponent as QRCodeIcon} from "../../icons/svgs/qrcode.svg";
import { ReactComponent as RadarChartIcon} from "../../icons/svgs/radarchart2.svg";
import { ReactComponent as TreeMapIcon } from "../../icons/svgs/treemap.svg";

import { DebounceInput } from 'react-debounce-input';

import { wrapLineBeforeBuy, getTimeDisplay } from "../../utils/tools";
import { adminWalletList, eTrendingData } from "../../utils/data";

import toast from 'react-simple-toasts';
import { TreeMap } from "../Chart/TreeMap";
import { PanelsDashboard } from "../Panels/PanelsDashboard";
var parse = require('html-react-parser');


export function DexPairItemList({ addStyle=null, addClass=null, height='100%'}) {

	const trendingchainnav = useRef(null);
	const trendingchannelnav = useRef(null);
	
	const navigator = useNavigator();

	const featureContent = useRef(null);

	const [listDataTime,setListDataTime] = useState('h24');
	const [listDataSort, setListDataSort ] = useState('desc');
	const [listCriteria, setListCriteria] = useState('trending');

	const [ draggingToken, setDraggingToken ] = useState(false);
	const [ dirtyList, setDirtyList ] = useState(false);

	const [ trendingChannels, setTrendingChannels] = useState([]);
	const [ showTrendingChannels, setShowTrendingChannels] = useState(false);
	const [ trendingChannelsX, setTrendingChannelsX] = useState(0);

	const [searchString, setSearchString] = useState("");
	
	let { contractAddress, handleContractAddress, screens } = useNavigator();


	let {
		fetchSocialSearch,
		socialSearch,
		loadingSocial,
		userPage,
		exploreUsers
		// searchString,
		// setSearchString,
	} = useSocial();

	let {
		user,
		adminWallet,
		walletAddress,
		dashboardAccess
	} = useWallet();

	
	let {
		ethTrending,
		ethTrendingUpdated,
		pageSize,
		calls,
		handleCalls,
		basePage,
		listPage,
		handleListPage,
		news,
		modalVisible,
		handleModalTitle,
		handleModalVisible,
		handleTokenListModal,
		handleQrurl,
		handleQrModal,
		sideBarLeft,
		sidebarRight,
		radarChartInit,
		treeChartInit,
		handleRadarChartInit,
		handleTreeChartInit
	} = useCore();

	let {
		dexView,
		ethTrendingChain,
		ethTrendingKey,
		handleEthTrendingChain,
		handleEthTrendingKey,
		trendingChain,
		handleTrendingChain,
		ethTrendingTokens,
		fetchEthTrendingTokens,
		trendingTokens,
		trendingTokensChannels,
		token,
		pairs,
		handleDexView,
		listView,
		handleListView,
		exploreLists,
		tokenLists,
		listTokens,
		saveListOrder,
		handleListTokens,
		tokenList,
		handleTokenList,
		newPairs,
		showTicker,
		handleShowTicker,
		loading,
		homeMeta,
		trendingView,
		handleTrendingView,
		toggleListView
		// editFilters,
		// handleEditFilters,
		// tokenFilters,
	} = useDex();



	useEffect(() => {
		if(listView == 'trending'){

			const checkIfClickedOutside = e => {
				
				if (showTrendingChannels &&
					trendingchainnav.current &&
					!trendingchainnav.current.contains(e.target) &&
					trendingchannelnav.current &&
					!trendingchannelnav.current.contains(e.target)
				) {
					setShowTrendingChannels(false);
				}
			}
	
			document.addEventListener("click", checkIfClickedOutside)
	
			return () => {
				// Cleanup the event listener
				document.removeEventListener("click", checkIfClickedOutside)
			}

		}
		

		// console.log(chains)
	}, [ listView, showTrendingChannels ]);
	
	useMemo(() => {
	
		// console.log('trending tokens',eTrendingData[ethTrendingChain], trendingTokens)
		setTrendingChannels([]);
		
	}, [ trendingTokens ]);

	useEffect(() => {
		
		scrollToInitial();	
		
	}, [ listView, ethTrendingKey ]);

	const scrollToInitial = () => {
		if (featureContent.current) {
			featureContent.current.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const onListTimeClick = (v) => {
		// console.log(v, listDataTime, listDataSort);
		if(listDataTime == v){
			if(listDataSort=='desc'){
				setListDataSort('asc');
			}else{
				setListDataSort('desc');
			}
			
		}else{
			setListDataTime(v);
		}
		
	}

	const onListCriteriaClick = (v) => {
		setListCriteria(v);
	}

	const toggleListDataSort = () => {
		let fmls = '';
		if(listDataSort == "desc"){
			fmls = "asc";
		}else{
			fmls = "desc";
		}
		setListDataSort(fmls);
	}

	const onTrendingChannelClick = (v) => {
		if(ethTrendingKey == v){
			setShowTrendingChannels(false);
		}else{
			handleEthTrendingKey(v);
		}

	}

	const onTrendingChainClick = (v) => {
		let tc = ['ethereum','base','solana','tron','ton','sui'];
		let xIndex = tc.indexOf(v);
		setTrendingChannelsX(xIndex*44);
		if(trendingView=="panel"){
			// console.log(ethTrendingKey,v)
			if(ethTrendingChain !== v){
				handleEthTrendingChain(v);
				handleTrendingChain(v);
				// console.log('handle')
			}
		}else{
			if(trendingChain == v){
				setShowTrendingChannels(!showTrendingChannels);
			}else{
				handleTrendingChain(v);
				setShowTrendingChannels(true);
			}
		}
		

	}

	const onDexTokenClick = (p) => {
		// handleTokenPair(p);
		// // handleTokenToolbarVisible(true);
		// closeModal();
	}

	const toggleShowTicker = () => {
		handleShowTicker(!showTicker);
	}

	const handleEditList = async(l=null,wait=null) => {
		if(wait){
			setTimeout(() => {
				handleModalVisible(true);
				handleModalTitle("Edit List");
				handleTokenListModal("edit");
			},2500)
		}else{
			handleModalVisible(true);
			handleModalTitle("Edit List");
			handleTokenListModal("edit");
		}
		
	}

	const openQRModal = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// handleModalTitle(nft.name + ' - #' + nft.token_id);
		// let qu = `${process.env.REACT_APP_ARTEMIS_URL_IP}/assets/${ca}/${nft.token_id}`;
		// console.log(qu);
		// handleQrurl(qu);

		let mt = "0xdex_list";
		// console.log(mt);
		handleModalTitle(mt);


		handleQrurl(`${process.env.REACT_APP_ARTEMIS_APP_URL}/list/${tokenList.slug}`);


		handleModalVisible(true);
		handleQrModal(true);
	}

	

	const handleOnDragEnd = (result) => {
		
		// console.log(result);

		setTimeout(() => {
			setDraggingToken(false)
		},100);

		if(result.destination?.index !== result.source?.index){
			const newList = Array.from(tokenList.tokens);
			const [movedToken] = newList.splice(result.source.index, 1);
			newList.splice(result.destination.index, 0, movedToken);

			handleTokenList({
				...tokenList,
				tokens: [...newList]
			});
			setDirtyList(true);
		}
		
	};

	const handleOnDragStart = (result) => {
		setDraggingToken(true);
		// console.log(result);
	};

	const updateListOrder = () => {
		saveListOrder();
		setDirtyList(false);
		toast(parse('<span className="mute">list</span> saved'));
	}

	

	const changeExploreSearch = (e) => {
		
		let s = e.target.value;
		// console.log(s.length,'explore serach')
		
		if(s){
			setSearchString(s);
			
		}else{
			setSearchString("");
		}
		fetchSocialSearch(s);
	}

	const validateSearch = (v) => {
		// let re = /^(?! )[a-zA-Z0-9āēīōūĀĒĪŌŪ_]*( ?[a-zA-Z0-9āēīōūĀĒĪŌŪ_]+)*$/i;
		let re = /^(?! )[a-zA-Z0-9āēīōūĀĒĪŌŪ_.]*( ?[a-zA-Z0-9āēīōūĀĒĪŌŪ_.]+)* ?$/i;

		let dataCheck = re.test(v);
		if(v.length > 33){
			return false;
		}
		return dataCheck;
	}

	

	

	
	

	

	const copyLink = () => {
		toast(parse('<span className="mute">link</span> copied'));
	}

	const trendingValuesFormat = Object.keys(eTrendingData[trendingChain]).map(k => 'string');
	const trendingImages = Object.keys(eTrendingData[trendingChain]).map(k => require(`../../icons/trending/logo_${[k]}.png`));
	const trendingNames = Object.keys(eTrendingData[trendingChain]).map(k => eTrendingData[trendingChain][k].name);
	const trendingValues = Object.keys(eTrendingData[trendingChain]).map(k => k);


	const openList = async (l) => {
		// console.log(e)
		// handleTokenList(null);
		if(token){
			handleListView("list");
			handleListPage(l.slug);
			await navigator.push(`/${token.chainId}/${token.pair.address}?view=list&list=${l.slug}`)
		}else{
			await navigator.push(`/list/${l.slug}`);
		}
		
	}

	const avatarSrc = tokenList && tokenList.owner?.avatarFile ? `${process.env.REACT_APP_MEDIA_URL}${tokenList.owner.avatarFile}?t=${Math.floor(new Date().getTime() / (1000 * 60 * 60))}` : require('../../images/default_profile_avatar.jpg');


	// { calls.map((c,i) => (
	// 	<CallItem key={`call${i}`} item={c} />
	// ))}

	// const memoCalls = useMemo(() => {
	// 	return calls.map((c,i) => (
	// 		<CallItem key={`call${i}`} item={c} />
	// 	))
	// }, [calls]);


	const memoCalls = React.useMemo(() => calls, [calls]);

	const MemoMasonry = React.memo(Masonry);

	return (
		<>

			<div style={addStyle && addStyle} className={classNames("feature-panel", addClass && addClass)}>
				
				
				
				
				

				
				{ listView == "list" && listPage && tokenList && tokenList.privateList == undefined ? (
					<div className="list-title">
						{ !walletAddress || walletAddress !== tokenList.walletAddress ? (
							<div onClick={() => navigator.push(`/${tokenList.owner.username}`)} style={{padding:"4px 7px",alignItems:"center"}} className="data-panel interactive align center">
								<div style={{marginRight:5}} className="user-avatar"><img src={avatarSrc} /></div>
								<div style={{marginRight:3}} className="data-title highlight">{tokenList.owner.name.length > 3 ? tokenList.owner.name : tokenList.owner.username}</div>
							</div>
						):null}

						<div style={{marginLeft:4,fontSize:14}} className="icon-button-list"/>
						<div style={{
							marginLeft:7,
							backgroundColor: tokenList.color,
							borderColor: tokenList.color
							}} className="color-box"></div>
						<div style={{marginLeft:7,marginRight:7}} className="data-value">
							{tokenList.name}
						</div>
						<div className="count-circle">{tokenList.tokens.length}</div>

						<div style={{marginTop:0,marginLeft:3,transform:'scale(.84)'}} className="coin-links small">
							<CopyToClipboard
								text={`${process.env.REACT_APP_ARTEMIS_URL}/list/${tokenList.slug}`}
								onCopy={copyLink}
								style={{cursor:"pointer"}}
									
							>
								<div onClick={null} data-tip={`copy link`} className="coin-link">

									<div style={{fontSize:14}} className="icon-button-copy"/>

								</div>
							</CopyToClipboard>

						</div>

						<div style={{marginLeft:11}} className="coin-links">

							{ walletAddress && walletAddress == tokenList.walletAddress ? (
								<div data-tip={`edit list`} onClick={handleEditList} className="coin-link">
									<div style={{fontSize:20}} className="icon-button-edit-3"/>
								</div>
							):null}

							
							
							<div style={{marginRight:7}} data-tip={`QR code`} onClick={openQRModal} className="coin-link">
								<div className="svg-icon">
										<QRCodeIcon/>
								</div>
							</div>
							
						</div>

						{ tokenList.community ? (
							<div style={{opacity:.7,marginRight:11}} className="icon-button-globe"/>
						):null}

						{ tokenList.admin && tokenList.walletAddress == walletAddress ? (
							<div style={{opacity:.7,marginRight:11}} className="icon-button-settings"/>
						):null}

						{ tokenList.private ? (
							<div style={{opacity:.7,marginRight:11}} className="icon-button-lock"/>
						):null}

						{ tokenList.live ? (
							<div style={{marginRight: 11, marginTop: 2, borderColor: "#01be8c", background: "#01be8c"}} className="color-box"></div>
						):null}

						{ dirtyList ? (
							<button onClick={updateListOrder} style={{padding: '3px 9px',maxHeight:27, marginLeft: 7}} className="small action">
								save
							</button>
						):null}
						
					
					</div>
				):null}


				
				
				{ listView == "trending" ? (
					<div style={{position:"relative",width: '100%', display:"flex",background: '#09131587'}}>
						
						{trendingView == "list" || trendingView == "panel" ? (
							<div style={{position:"fixed",zIndex:444,top: token && !sidebarRight && showTicker ? 336 : token && !sidebarRight ? 144 : showTicker ? 292 : 100,right:14}}>
								<h1 style={{fontSize:11}}>
									<span className="mute">updated</span> {getTimeDisplay(ethTrendingUpdated)} {getTimeDisplay(ethTrendingUpdated) !== "now" ? <span className="mute">ago</span> : ""}
								</h1>
							</div>
						):null}
						
						

						<div style={{minHeight:44}} className="flex-column full">

							<div style={{position: trendingView == "panel" ? "fixed" : "relative",padding:4}} className="flex-row align center">
								<div ref={trendingchainnav} className="flex-row align center">

									{/* <div style={{margin:'12px 2px 7px 7px',opacity:.5,}} className="icon-button-link"></div> */}

									<TokenDataTabsInteractive
										title="trending"
										passClick={onTrendingChainClick}
										buttonValue={ethTrendingChain}
										buttonSubValue={showTrendingChannels ? trendingChain : ""}
										buttonValues={['ethereum','solana','base','tron','ton','sui']}
										values={ ["Ethereum Chain","Solana Chain","Base Chain","Tron Chain","Ton Chain", "Sui Chain"]}
										showValues={false}
										images={ [
											require(`../../icons/logo_chain_ethereum.png`),
											require(`../../icons/logo_chain_solana.png`),
											require(`../../icons/logo_chain_base.png`),
											require(`../../icons/logo_chain_tron.png`),
											require(`../../icons/logo_chain_ton.png`),
											require(`../../icons/logo_chain_sui.png`),
										] }
										// subImages={ [
										// 	null,
										// 	null,
										// 	null,
										// 	null
										// ]}
										valuesFormat={['string', 'string', 'string','string','string','string']}
										addStyle={{
											borderBottom:"1px solid #1d1d1d77",
											maxWidth: `${ (6 * 44) }px`,
											minWidth: 240,
											margin: 0,
											marginLeft: 0,
											// marginTop: '48px',
											borderRadius: '7px !important',
											overflow: 'hidden'
										}}
										stopPropagation={true}

										dataItemStyle={{maxWidth:60}}


										
										addClass="active-underline trending-nav"
										// labels={['5m','1h','6h','24h']}
									/>

									
									<div style={{marginLeft:11}} className="coin-links">

										<div onClick={() => handleTrendingView("panel")} data-tip={`Trending Panel`} className={classNames("coin-link active-border",trendingView=="panel" && "active")}>
											
											<div style={{width:24,height:24}} className="svg-icon">
												<div className="icon-button-columns"/>
											</div>
											
										</div>

										<div onClick={() => handleTrendingView("list")} data-tip={`Trending`} className={classNames("coin-link active-border",trendingView=="list" && "active")}>
											
											<div style={{width:24,height:24}} className="svg-icon">
												<div className="icon-button-menu"/>
											</div>
											
										</div>
									
										<div onClick={() => handleTrendingView("treemap")} data-tip={`Treemap`} className={classNames("coin-link active-border",trendingView=="treemap" && "active")}>
											
											<div style={{width:24,height:24}} className="svg-icon">
												<TreeMapIcon/>
											</div>
											
										</div>

										<div onClick={() => handleTrendingView("radar")} data-tip={`Trending Radar`} className={classNames("coin-link active-border",trendingView=="radar" && "active")}>
											
											<div style={{width:24,height:24}} className="svg-icon">
												<RadarChartIcon/>
											</div>
											
										</div>

									</div>

									

									
									
									

								</div>



								
							</div>
									
							{/* <h1>{trendingView}</h1>
							 */}
							{ trendingView == "list" || trendingView == "radar" || trendingView == "treemap" ? (
								
								<div ref={trendingchannelnav} className={classNames("trending-channels", showTrendingChannels && "show")} style={{position:"absolute",top:44,left:`${trendingChannelsX}px`,zIndex:111}}>
									<TokenDataTabsInteractive
										title="trending"
										passClick={onTrendingChannelClick}
										buttonValue={ethTrendingKey}
										buttonValues={trendingValues}
										values={ trendingNames }
										showValues={false}
										images={ trendingImages }
										valuesFormat={trendingValuesFormat}
										addStyle={{
											borderBottom:"1px solid #1d1d1d77",
											// maxWidth:999,
											marginTop:0,
											maxHeight:50,
											borderRadius: '7px !important',
											overflow: 'hidden',
											justifyContent: "flex-start"
										}}
										dataItemStyle={{maxWidth:60}}
										addClass="active-underline trending-nav"
										// labels={['5m','1h','6h','24h']}
									/>	

								</div>
								

							):null}

							{ trendingView !== "panel" ? (

								<div style={{}} className="flex-row trending-nav-dex">

									{ trendingTokensChannels[ethTrendingKey] !== undefined ? (
										<div style={{marginLeft:0, minWidth: 113, maxHeight: 35}} className="flex-row data-panel">
										
											<>
												<div style={{transform: 'scale(.9)',marginRight:7}} className="svg-icon">
													<img src={require(`../../icons/logo_chain_${ethTrendingChain}.png`)} />
												</div>
												<div className="svg-icon">
													<img src={require(`../../icons/trending/logo_${ethTrendingKey}.png`)} />
												</div>

												<div style={{marginTop:1,marginLeft:11,opacity:.7}} className="rank-number rank-2">{trendingTokensChannels[ethTrendingKey].count}</div>
												<p style={{fontSize:11,margin:0,minWidth:122,lineHeight:'23px',fontWeight:'600',marginLeft:11}}>{trendingTokensChannels[ethTrendingKey].name}</p>
											
											</>
											
											
											
										</div>
									):null}

									{ trendingView == "list" ? (
										<div style={{borderRadius:'4px',overflow:'hidden', marginLeft:7}} className="data-row interactive-items">
											<div onClick={toggleListDataSort} style={{display:"flex",alignItems:'center',justifyContent:'center'}} className="data-item">
												<div className={listDataSort == "desc" ? "icon-arrow-down-thick" : "icon-arrow-up-thick"}/>
											</div>
										</div>
									):null}

									<TokenDataTabsInteractive
										title="price"
										bookend={false}
										addStyle={{borderRadius:'4px',marginRight:11,overflow:'hidden'}}
										disabled={trendingView == "radar" ? true : false}
										passClick={onListCriteriaClick}
										buttonValue={listCriteria}
										buttonValues={['trending','price','volume']}
										svgs={[<Flame/>]}
										styles={[{padding:'7px 7px 0'}]}
										values={ ['','icon-button-dollar-sign','icon-button-bar-chart']}
										dataTips={['trending','price', 'volume']}
										valuesFormat={['string','string','string']}
										labels={['','','']}
									/>

									<TokenDataTabsInteractive
										title="price"
										bookend={false}
										addStyle={{borderRadius:'4px',overflow:'hidden',marginLeft:7}}
										disabled={trendingView == "radar" || listCriteria == "trending" ? true : false}
										// addClass={'centerlast'}
										passClick={onListTimeClick}
										buttonValue={listDataTime}
										buttonValues={['m5','h1','h6','h24']}
										values={ ['5m','1h','6h','24h']}
										dataTips={[]}
										valuesFormat={['string','string','string','string']}
										labels={['','','','']}
									/>

								</div>
							):null}
							
							
							
						</div>
						
					</div>
				):null}
				
				
				{ listView == "newpairs" && dashboardAccess && dashboardAccess?.newpairs ? (
					<PairList visible={true} height={height} pairs={pairs}/>

				):null}
			


				{ listView !== "newpairs" ? (
					<div ref={featureContent} style={listView == "newpairs" || listView == "trending" ? {paddingBottom:0} : {padding:'0px 0 44px 0px'}} className={classNames("feature-content", listView == "lists" && "wrap-content")}>

						{ listView == "portfolio" ? (
							<Portfolio noTitle={true}/>
						):null}
	
						{ listView == "list" && listPage && tokenList ? (
							<>
								{ tokenList.privateList !== undefined ? (
									<h2 style={{margin:44}}>Access prohibited.</h2>
								):(
									<ListPresentation list={tokenList} showList={false}/>
								)}
							
							</>
							
							
						):null}

						{listView == "user" && userPage ? (
							<UserPage u={userPage}/>
						):null}


						{ listView == "lists" ? (
							
							<>

								<div className="flex-column">
									<div style={{padding:'4px 7px'}} className="feature-card-tokens">
										{tokenLists.map((l,i) => (
											<TokenListItem l={l} key={`list${i}`} passClick={openList} editList={handleEditList} />
											
												
										))}

									</div>
									
								</div>

								{/* <div style={{margin:5}} className="feature-card">
									<div className="feature-card-title">
										<div style={{zIndex:-1}} className="ticker-overlay"/>
										
										<div className="text-content">
											<div className="icon-button-globe"></div>
											<p>explore</p>
										</div>
									</div>
									
								</div> */}
								
							</>
				
						):null}


						{ listView == "explore" ? (
							
							<>

								{/* <div style={{margin:5}} className="feature-card">
									<div className="feature-card-title">
										<div style={{zIndex:-1}} className="ticker-overlay"/>
										
										<div style={{display:"flex",alignItems:"center"}} className="text-content">
											<div className="icon-button-globe"></div>
											<p>explore</p>
										</div>
									</div>
									
								</div>	 */}



								<div style={{paddingLeft:10,maxWidth:444,width:"100%",marginBottom:11}} className="input-group">

									<DebounceInput
										// className={`outline${usernameTemp.length < 1 || profile.username == usernameTemp ? "" : usernameTempValid && usernameUnique ? " active outline-valid" : " outline-danger"}`}
										className="outline"
										placeholder="search"
										autoComplete="off"
										// autoFocus
										minLength={1}
										debounceTimeout={700}
										// onKeyDown={e => handleUsernameSearched(false)}
										// onKeyUp={(e) => handleExploreSearch(e.target.value)}
										value={searchString}
										onChange={e => changeExploreSearch(e)}
									>
										
									</DebounceInput>
									
								
										
								</div>    

								



								{/* <div className="flex-column">
									<div className="feature-card-tokens">
										{tokenLists.map((l,i) => (
											<TokenListItem l={l} key={`list${i}`} passClick={openList} editList={handleEditList} />
											
												
										))}

									</div>
									
								</div> */}


								<div style={{margin: '4px 7px 0px'}} className="flex-column">
									{/* <div className="feature-card">
										<div className="feature-card-title">
											<div style={{zIndex:-1}} className="ticker-overlay"/>
											<div className="text-content">
												<p>featured users</p>
											</div>
										</div>
										
									</div> */}
									
									<div className="feature-card-tokens">

										{ loadingSocial || searchString.length < 1 || (searchString.length > 0 && ( socialSearch.users?.length > 0 || socialSearch.lists?.length > 0 )) ? (
											<></>
										):(
											<h2 style={{margin:22}}>No Results</h2>
										)}

										
										{ searchString.length > 0 ? (
											<>
												{ socialSearch.users
													// .filter(n => n.pair?.data )
													// .sort((a, b) => b.defaultPair.data.priceChange.h24 > a.defaultPair.data.priceChange.h24 ? 1 : -1)
													.map((u,i) => (
													
													<UserCard key={`u${i}`} u={u}/>
													
												))}
											</>
										):(
											<>
												{ exploreUsers ? (
													<>
														{ exploreUsers
															// .filter(n => n.pair?.data )
															// .sort((a, b) => b.defaultPair.data.priceChange.h24 > a.defaultPair.data.priceChange.h24 ? 1 : -1)
															.map((u,i) => (
															
															<UserCard key={`u${i}`} u={u}/>
															
														))}
													</>
												):null}
												
											</>
										)}
										


										
									</div>
									
									</div>
								


								<div style={{padding:'0px 7px'}} className="flex-column">
									<div className="feature-card-tokens">

										{ searchString.length > 0 ? (
											<>
												{ socialSearch.lists?.length > 0 ? (
													<>
														{socialSearch.lists
														.sort((a, b) => b.live && !a.live ? 1 : b.followers > a.followers ? 1 : b.updated > a.updated ? 1 : -1)
														.map((l,i) => (
															<TokenListItem l={l} key={`list${i}`} passClick={openList} editList={handleEditList} />
															
														))}
													</>
												):null}
												
												
											</>
										):(
											<>
												{exploreLists
												.sort((a, b) => {
													if (b.live && !a.live) return 1;
													if (a.live && !b.live) return -1;
													if (b.followers !== a.followers) return b.followers - a.followers;
													return new Date(b.updated) - new Date(a.updated);
												})
												.filter((tl) => tl.tokens.length )
												.map((l,i) => (
													<TokenListItem l={l} key={`list${i}`} passClick={openList} editList={handleEditList} />
													
												))}
											</>
										)}
										

									</div>
									
								</div>
								
							</>
				
						):null}

						

						{ listView == "list" && listPage && tokenList && tokenList.tokens?.length ? (
							<>
								
								<DragDropContext
									onDragEnd={handleOnDragEnd}
									onDragStart={handleOnDragStart}
								>
									<Droppable droppableId="droppable">
										{(provided) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
											style={{ padding: walletAddress ? '0px 5px' : '0px 5px' }}
										>
											
											{/* { listTokens.filter(n => n.pair?.data).map((t,i) => ( */}
											{ tokenList.tokens.map((t, i) => (

												<Draggable key={t._id} draggableId={t._id} index={i}>
													{(provided) => (
														<div
															{...provided.droppableProps}
															ref={provided.innerRef}
															style={{ padding: '2px 0 0' }}
														>
															<DexPairItem
																key={`list${i}`}
																i={i}
																withSpace={true}
																// view="portfolio"
																hideRank={true}
																passClick={onDexTokenClick}
																removeOption="list"
																pairData={t.pair?.data}
																token={t}
																list={tokenList}
																pair={null}
																coreToken={token}
																dragHandleProps={provided.dragHandleProps}
																draggableProps={walletAddress && walletAddress == tokenList?.walletAddress ? provided.draggableProps : null}
																innerRef={provided.innerRef}
																dragging={draggingToken}
																activeColor={tokenList.featuredToken?._id == t._id || tokenList.featuredToken == t._id ? tokenList.color : false}
															/>

															{provided.placeholder}

														</div>

													)}
												</Draggable>
												
											))}

											{provided.placeholder}
										</div>
										)}
									</Droppable>
								</DragDropContext>





















								

								
							</>
						):(
							<></>
						)}

						{/* <h1>{pageSize.width}</h1> */}

						{ listView == "calls" ? (
							
							<>
								{pageSize.width < 768 ? (
									<>
										{calls.map((c,i) => (
											<CallItem addStyle={{width:"100%"}} key={`call${i}`} item={c} />
										))}
									</>
								):(
									<div
										// columnsCount={Math.round(pageSize.width / 400)}
									>
										{calls.map((c,i) => (
											<CallItem key={`call${i}`} item={c} />
										))}
									</div>
								)}
							</>
								
						):null}
						


						{ listView == "home" ? (
							<HomeMeta/>
						):null}
						
						

						{ listView == "news" ? (

							<>
							
								{ news ? (
									<>
										{pageSize.width < 768 ? (
											<>
												{news.filter(n => n.message?.length).map((t, i) => (

													<NewsItem addClass={'grid-item'} item={t} key={`ni${i}`}/>

												))}
											</>
										):(
											<MemoMasonry
												columnsCount={Math.round(pageSize.width / 450)}
											>	
												{news.filter(n => n.message?.length).map((t, i) => (

													<NewsItem addClass={'grid-item'} item={t} key={`ni${i}`}/>

												))}
											
											</MemoMasonry>
										)}
									</>

									
								):null}
							
							
							</>
							
								
							

							// <div className="grid-container">
							// 	{ news ? (
							// 		<>
							// 			{news.filter(n => n.message?.length).map((t, i) => (

							// 				<NewsItem addClass={'grid-item'} item={t} key={`ni${i}`}/>

							// 			))}
							// 		</>
							// 	):null}

							// </div>
						):null}

						{/* { listView=="trending" && ethTrending && ethTrendingTokens[ethTrendingKey] !== undefined ? (
							<>
								<PairList pairs={ethTrendingTokens[ethTrendingKey]}/>
							</>

						):null} */}

						

						{ listView=="trending" && ethTrending ? (
							<div style={{height: trendingView == "panel" ? "calc( 100% )" : "initial"}} className="flex-row full align center">

								{ trendingView == "panel" && trendingTokens && trendingTokens.length ? (
									<PanelsDashboard type="trending" channels={trendingChannels} />
								):null}
									
							


								{ trendingView == "list" && trendingTokens && trendingTokens.length ? (
									<div style={{width:'100%'}} className="flex-column">
										
											<>
										
												{trendingTokens
												.filter(t => t.trending[ethTrendingKey] !== undefined)
												.sort((a,b) => a.rank > b.rank ? 1 : -1 )
												.sort((a, b) => {
													
													if(listCriteria === "trending"){
														if(listDataSort == "asc"){
															return a.trending[ethTrendingKey].rank < b.trending[ethTrendingKey].rank ? 1 : -1
														}else{
															return a.trending[ethTrendingKey].rank > b.trending[ethTrendingKey].rank ? 1 : -1
														}
													}
													
													if (listCriteria === "price") {
														if(listDataSort == "asc"){
															return a.data.priceChange[`${listDataTime}`] > b.data.priceChange[`${listDataTime}`] ? 1 : -1
														}else{
															return a.data.priceChange[`${listDataTime}`] < b.data.priceChange[`${listDataTime}`] ? 1 : -1
														}
													}

													if (listCriteria === "volume") {
														if(listDataSort == "asc"){
															return a.data.volume[`${listDataTime}`] > b.data.volume[`${listDataTime}`] ? 1 : -1
														}else{
															return a.data.volume[`${listDataTime}`] < b.data.volume[`${listDataTime}`] ? 1 : -1
														}
													}

													return 
													return 0;
													})
												.map((tp,i) => {
													if(tp.address && tp.data ){
														return (
															<DexPairItem
																key={`tdpi${i}`}
																withSpace={true}
																i={i}
																rankAnimation={false}
																// passClick={onDexTokenClick}
																pairData={tp.data}
																pair={tp}
																coreToken={token}
															/>
														)
													}else{
														return (
															<h2>{tp.address}</h2>
														)
													}
												})}
											</>
										

									</div>

								):null}
								
								{ (trendingView == "radar" || radarChartInit) ? (
									<div style={{ display: trendingView == "radar" ? "flex" : "none" }} className="flex-column">
										<div className="flex-row full" style={{justifyContent:'center',alignItems:'center'}}>
											<RadarChart
											
											trendingChannels={trendingValues}
											trendingNames={trendingNames}
											// trendingTokens={ethTrendingTokens[ethTrendingKey]}
											trendingTokens={trendingTokens}
											passClick={onTrendingChannelClick}
											/>
										</div>
									</div>
								):null}


								{ listView=="trending" && trendingView == "treemap" ? (
									<div className="flex-column">
										<div className="flex-row full" style={{justifyContent:'center',alignItems:'center'}}>
											<TreeMap
											dataTimeKey={listDataTime}
											dataCriteria={listCriteria}
											tokens={trendingTokens}
											// passClick={onTrendingChannelClick}
											/>
										</div>
									</div>
								):null}

								
								
							</div>

						):null}

						
						

					</div>
				):null}
				
			</div>



		</>
	);
}
	