


import React, { useState, useEffect, useCallback, useRef} from "react";

import { useDex } from "../../hooks/useDex";

import classNames from "classnames";


import { InputSelect } from "../../components/Input/InputSelect";

import { ReactComponent as Megaphone} from "../../icons/svgs/megaphone.svg";
import { ReactComponent as Newspaper} from "../../icons/svgs/newspaper.svg";
import { ReactComponent as Seedling} from "../../icons/svgs/seedling.svg";
import { ReactComponent as Wallet} from '../../icons/svgs/wallet.svg';
import { ReactComponent as Rocket} from "../../icons/svgs/rocket.svg";
import { ReactComponent as Flame} from "../../icons/svgs/flame2.svg";
import { ReactComponent as Marquee} from '../../icons/svgs/marquee.svg';


import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useNavigator } from '../../hooks/useNavigator';


export function DexMainMav({ }) {


    const navigator = useNavigator();

    const [ tLists, setTLists] = useState([]);
    const [ tListsValues, setTListsValues] = useState([]);

    const {
        pageSize,
        handleModalTitle,
		handleModalVisible,
		handleTokenListModal,
    } = useCore();

    let {
        user,
        adminWallet,
        walletAddress,
        dashboardAccess,
    } = useWallet();

    const {
        listView,
        toggleListView,
        showTicker,
		handleShowTicker,
        tokenLists,
        token,
        tokenList,
        handleListView,
        dexView
    } = useDex();


    useEffect(() => {
        if(dashboardAccess && dashboardAccess.lists !== undefined && tokenLists?.length ){

            const tListsmap = tokenLists.map((t, i) => {
                return `${t.name} (${t.tokens.length})`;
            });
            const tListsValuesmap = tokenLists.map((t, i) => {
                return t.slug;
            });
        
            let tListstemp = [
                ...tListsmap
            ];
        
            let tListsValuestemp = [
                ...tListsValuesmap
            ]
            console.log(tokenLists.length,dashboardAccess.lists);
            if(tokenLists.length < dashboardAccess.lists){
                tListstemp.push("++ new list ++");
                tListsValuestemp.push("new");
            }
    
            setTLists(tListstemp);
            setTListsValues(tListsValuestemp);

        }
        
    }, [ dashboardAccess, tokenLists ]);

    useEffect(() => {

      
    }, [ ]);

    const optionsClick = () => {

    }


    const toggleShowTicker = () => {
		handleShowTicker(!showTicker);
	}

    const onTokenListChange = (v) => {

		
		if(v == "new" && (tokenLists.length < 3 || adminWallet)){
			handleCreateList();
		}else{
			handleListView("list");

			if(token){
				navigator.push(`/${token.pair.chainId}/${token.pair.address}?view=list&list=${v}`);
			}else{
				navigator.push(`/list/${v}`);
			}

			toggleListView("list",v,null,true);
		}
		
		
	}

    const handleCreateList = () => {
		handleModalVisible(true);
		handleModalTitle("Create List");
		handleTokenListModal("create");
	}


    const onTokenListFocus = () => {
		if(tokenLists[0] == undefined){
			handleCreateList();
		}
	}


  

    const listSelectorClick = (e) => {
      
        if(listView !== "list"){
            e.preventDefault();
            e.stopPropagation();
            if(!tokenList?.slug){
                if(tokenLists[0] !== undefined){
                    toggleListView("list",tokenLists[0].slug,null,true);
                }
                
            }else{
                toggleListView("list",tokenList?.slug,null,true);
            }
        }
            
    }


    return (

        <div style={pageSize ? { padding: 3,maxWidth: pageSize.width } : {padding:3} } className="feature-title flex-row justify space-between">
            {/* <h1>{basePage}{listPage}</h1> */}
            <div>
                <div style={{alignItems:'center', paddingRight: 53, maxWidth: pageSize.width}} className={classNames("coin-links bw list-nav")}>

                    <div onClick={() => { toggleListView("home") } } data-tip="home" style={{maxWidth:120,marginLeft:7,marginRight:7}} className={classNames("coin-link active-border", listView == "home" && dexView == "list" && 'active')}>
                        <div style={{width:14,height:14}} className="svg-icon">
                            <div style={{fontSize:14}} className="icon-button-home"/>
                        </div>
                    </div>
                    
                    
                    { walletAddress ? (
                        <div onClick={() => { toggleListView("portfolio") } } data-tip="portfolio" style={{maxWidth:120,minWidth:120}} className={classNames("coin-link active-border", listView == "portfolio" && dexView == "list" && 'active')}>
                            <div style={{marginRight:7,transform:'scale(.8)'}} className="svg-icon">
                                <Wallet/>
                            </div>
                            <p style={{margin:0,fontSize:13,fontWeight:'500',marginRight:7}}>portfolio</p>
                            { user && user.portfolioTokens !== undefined && (
                                <div className="count-circle" style={user?.portfolioTokens?.length < 1 ? {  filter: 'saturate(0)', opacity: .3 } : {}}>
                                    {user.portfolioTokens.length}
                                </div>
                            )}
                        </div>
                    ):null}

                    { walletAddress ? (
                        <div onClick={listSelectorClick} style={{ marginLeft:7,cursor: listView == "list" ? "default" : "pointer"}} className={`flex-row align center`}>
                            <div style={{fontSize:14,color: listView == "list" ? "#4dc1f0" : "inherit"}} className="icon-button-list"/>
                            <div className="color-box" style={{marginLeft:7,marginRight:0,backgroundColor: tokenList?.color ? tokenList.color : "#444",borderColor: tokenList?.color ? tokenList.color : "#444", opacity: listView == "list" ? 1 : .5}}/>
                            <InputSelect
                                title={null}
                                onChange={onTokenListChange}
                                value={tokenList?.slug || 'null'}
                                options={tLists}
                                optionsValues={tListsValues}
                                onFocus={onTokenListFocus}
                                addClass={`button-icon-select xsmall ${listView == "list" ? "active" : ""}`}
                                addStyleInputGroup={{padding:"0 8px", minWidth:169, pointerEvents: listView == "list" ? "all" : "none"}}
                                noChangeValues={['new']}
                            />
                        </div>
                    ):null}

                    { walletAddress ? (
                        <div onClick={() => { toggleListView("lists") } } data-tip="lists" style={{minWidth:75, maxWidth:120}} className={classNames("coin-link active-border", listView == "lists" && dexView == "list" && 'active')}>
                            <div style={{width:14,height:14,marginRight: 7}} className="svg-icon">
                                <div style={{fontSize:14}} className="icon-button-list"/>
                            </div>
                            <p style={{margin:0,fontSize:13,fontWeight:'500'}}>lists</p>
                        </div>
                    ):null}
                    
                    

                    <div onClick={() => { toggleListView("explore") } } data-tip="explore" style={{minWidth:99, maxWidth:120}} className={classNames("coin-link active-border", listView == "explore" && dexView == "list" && 'active')}>
                        <div style={{width:14,height:14,minWidth:14,marginRight: 7}} className="svg-icon">
                            <div style={{fontSize:14}} className="icon-button-globe"/>
                        </div>
                        <p style={{margin:0,fontSize:13,fontWeight:'500'}}>explore</p>
                    </div>
                    

                    <div onClick={() => { toggleListView("trending") } } data-tip="trending" style={{minWidth:102, maxWidth:120}} className={classNames("coin-link active-border", listView == "trending" && dexView == "list" && 'active')}>
                        <div style={{marginRight:7,marginTop:2,width:18,minWidth:14,height:18}} className="svg-icon large">
                            <Flame/>
                        </div>
                        <p style={{margin:0,fontSize:13,fontWeight:'500'}}>trending</p>
                    </div>

                    <div onClick={() => { toggleListView("news") } } data-tip="news" style={{minWidth:75, maxWidth:120}} className={classNames("coin-link active-border", listView == "news" && 'active')}>
                        <div style={{marginRight:7,width:14,height:14}} className="svg-icon">
                            <Newspaper/>
                        </div>
                        <p style={{margin:0,fontSize:13,fontWeight:'500'}}>news</p>
                    </div>

                    <div onClick={() => { toggleListView("calls") } } data-tip="calls" style={{minWidth:75, maxWidth:120}} className={classNames("coin-link active-border", listView == "calls" && dexView == "list" && 'active')}>
                        <div style={{marginRight:7,width:14,height:14,transform:'scaleX(-1)'}} className="svg-icon no-fill">
                            <Megaphone/>
                        </div>
                        <p style={{margin:0,fontSize:13,fontWeight:'500'}}>calls</p>
                    </div>


                    { walletAddress && dashboardAccess?.newpairs ? (
                        <div onClick={() => { toggleListView("newpairs") } } data-tip="new pairs" style={{minWidth:114,marginRight:14}} className={classNames("coin-link active-border", listView == "newpairs" && dexView == "list" && 'active')}>
                            <div style={{marginRight:7}} className="svg-icon">
                                <Seedling/>
                            </div>
                            <p style={{margin:0,fontSize:13,fontWeight:'500'}}>new pairs</p>
                        </div>
                    ):null}

                    


                </div>


            </div>

            { (!token || token) && !showTicker ? (
                <div style={{position:"absolute",right:4, top: 5}} className="coin-links">
                    <div onClick={() => { toggleShowTicker() } } data-tip={!showTicker ? "show marquee" : "hide marquee"} style={{maxWidth:120}} className={classNames("coin-link", showTicker && 'active')}>
                        <div style={{width:14,height:14}} className="svg-icon">
                            <Marquee/>
                        </div>
                    </div>
                </div>
            ):null}
                
        </div>

    )
}







